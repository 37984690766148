main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', Fallback, sans-serif;
  font-size: 14px;
  @media screen and (max-width: 1382) {
    font-size: 12px;
  }
  line-height: 1.6;
  color: #646777
}

* {
  box-sizing: inherit; 
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color:  #646777;
  font-weight: 500;
}
 
ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

.color-accent {
  color: $color-accent;
}

.color-gray {
  color: $color-gray;
}

.color-green {
  color: $color-green;
}

.color-red {
  color: $color-red;
}

.cursor-pointer {
  cursor: pointer;
}

.divider {
  margin-top: 15px; 
}
.divider-disable-padding {
  padding: unset;
}
.component__divider {
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component__divider__tooltip {
  padding-bottom: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component__divider-title {
  text-transform: uppercase;
  color: $color-accent;
  font-weight: 500;
  margin: 0 10px;
}

.component__divider-line {
  height: 1px;
  background-color: #555555;
  opacity: 0.15;
  flex-grow: 1;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.loading-in-small-area {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  // background: #e2e2e2;
  // opacity: 0.5;

  &:before {
    content: '';
    position: absolute;
    width: 760px;
    height: 410px;;
    z-index: 1;
    top: 0;
    left: 0px;
    border-radius: 5px;
    background: #e2e2e2;
    opacity: 0.5;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }
  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 2;
    width: 48px;
    height: 48px;
    // top: calc(50% - 24px);
    top: 150px;
    left: calc(50% - 24px);
    
  }

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.loading-refresh {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0px;
  z-index: 9999;
  height:100%;
  max-height:100%;
  // overflow:hidden;   
  * {
      box-sizing: inherit;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
  }

  &:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    top: 0;
    left: 0px;
    border-radius: 5px;

    // @include themify($themes) {
      background-color:  white; // themed('colorBackground');
      opacity: 0.8;
      overflow: hidden
    // }
  }
  svg {
    fill: $color-accent;
    position: fixed;
    animation: refresh 1s infinite linear;
    z-index: 100;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    
  }

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}