#checkbox-label-content {
  width: max-content;
  input[type="checkbox"]{
    visibility: hidden;
    top: 10px;
    left: 30px;
    position: absolute;
    // display: contents;
  }
  
  #checklabel{
    height:35px;
    // min-width: 135px;
    width: max-content;
    display:block;
    border-radius: 20px;
    border: 1px solid #ddd;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 10px;
    &:hover:not(.disabled) {
      cursor: pointer;
      background-color: #a1c8ea !important;
    }
    span {
      padding: 0 15px;
      color: white;
    }
  }

  // #checklabel.disabled {
  //   cursor: unset !important;
  //   // background-color: rgb(118, 121, 132) !important
  // }
  
  input[type="checkbox"]:checked + label{
    // border: 2px solid black;
    background-color: #6fbbfd !important;
    &:hover {
      cursor: pointer;
      background-color:#6fbbfd !important;
    }
  }
}